<template>
    <div style="height: inherit; position: relative; width: 100%;">
        <b-card>
            <div class="m-1">
                <h1 style="display: flow-root;">공지사항
                    <b-button v-if="auth === true" style="float: right" variant="primary" @click="goWriteBoard">글쓰기</b-button>
                </h1>
                <b>※ 공지사항은 최대 5개까지만 표현됩니다.</b>
                <hr style="border: 1px double">
                <div class="m-0">
                    <b-table
                        ref="selectableTable"
                        :items="boardList"
                        :fields="boardFields"
                        hover
                        :busy="isBusy"
                        sort-by="id"
                        sort-desc
                        show-empty
                        style="font-weight: bold; max-width: 900px; margin:0 auto; border: 1px groove; border-radius: 0.5rem;"
                        selectable
                        responsive 
                        select-mode="single"
                        empty-text="등록된 공지사항이 없습니다."
                        @row-selected="onRowSelected"
                    >
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <br>
                        <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(check)="row">
                        <div v-if="row.item.check">
                            <feather-icon
                                icon="CheckIcon"
                                size="20"
                                style="color: blue"
                            />
                        </div>
                        <div v-else>
                            <feather-icon
                                icon="MinusIcon"
                                size="20"
                                style="color: red"
                            />
                        </div>
                    </template>
                </b-table>
                </div>
                
            </div>
        </b-card>
        <b-modal
            :ref="`selectableTableModal`" 
            hide-header
            hide-footer
            centered
            no-close-on-backdrop
            no-stacking
            :title="`${selected.title}`">
            <b style="display: contents">
                <span style="font-size: large;">제목: {{ selected.title }} </span>    
                <b-button v-if="auth === true" style="float: right" variant="outline-danger" @click="deleteInfoMsg">삭제</b-button>
                <br>
                날짜: {{ dateFommater(selected.createdAt) }}
                <!-- <br>
                <div v-if="selected.updatedAt != null">
                수정 일시: {{ dateFommater(selected.updatedAt) }}
                </div> -->
            </b>
            <hr>
            <b-form-textarea 
                v-model="selected.contents" 
                readonly
                plaintext
                rows="3"
                max-rows="20"
                no-resize
                style="font-size: larger; font-weight: bold;"
            ></b-form-textarea>
            <hr>    
                      
            <div style="float: right;">
                <b-button class="mr-1" v-if="auth === true" variant="outline-warning" @click="modifyInfoMsg">수정</b-button>
                <b-button variant="outline-info" @click="closeInfoMsg">공지사항을 확인하였습니다.</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import {
    BButton,
    BFormTextarea,
    BCard,
    BTable,
    BModal,
    BSpinner
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import moment from 'moment'
import store from '@/store'
import board from '@/store/board'

export default {
    components: {
        BButton,
        BFormTextarea,
        BCard,
        BTable,
        BModal,
        BSpinner
    },
    data() {
        return {
            boardFields: [
                /* {
                    label: 'No.',
                    key: 'id',
                }, */
                {
                    label: '제목',
                    key: 'title',
                },
                {
                    label: '날짜',
                    key: 'createdAt',
                    formatter: value => `${moment(value).format('YY-MM-DD')}`
                },
                {
                    label: '확인',
                    key: 'check',
                    formatter: value => (value)? '확인함': '미확인'
                },
            ],
            boardList: [],
            selected: null,
            isBusy: false,
            auth: false
        }
    },
    computed: {
        
        /* imgUrl() {
            return require(`@/assets/images/pages/1.png`)
        } */
    },
    created() {
        
        this.collectBoardList()
        

        this.selected = this.boardList[0] || []
        if(getUserData().role === 'admin') this.auth = true
    },
    methods: {
        async checkInfo(userId, boardId) {
            const result = await store.dispatch('board/getViewList', {id: boardId})
            if(result.data.views.findIndex(e => e.id === userId) > -1) return true
            return false
        },
        dateFommater(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
        goWriteBoard() {
            this.$router.push({ name: 'board-write', params: { writeType: 'create' } })
        },
        async onRowSelected (items) {
            if(items.length > 0) {
                this.selected = items[0]
                this.$refs[`selectableTableModal`].show()
                this.$refs[`selectableTable`].clearSelected()
            }
        },
        async collectBoardList () {
            this.isBusy =  true
            const result = await store.dispatch('board/fetchBoard', {})
            this.boardList = result.data
            this.boardList.reverse()
            this.boardList = this.boardList.slice(0, 5)
            for(let board of this.boardList) {
                let idx = board.views.findIndex(e => e.id === getUserData().id)
                if(idx > -1) board.check = true
                else board.check = false
            }
            this.isBusy =  false
        },
        async closeInfoMsg() {
            if(!await this.checkInfo(getUserData().id, this.selected._id)) {
                await store.dispatch('board/setViewList', {id: this.selected._id, body: {id: getUserData().id} })
                this.collectBoardList()
            }
            this.$refs[`selectableTableModal`].hide()
        },
        modifyInfoMsg() {
            this.$router.push({ name: 'board-write', params: { writeType: 'modify', writeData: this.selected } })
        },
        async deleteInfoMsg() {
            this.$bvModal.msgBoxConfirm(<b style="font-size: large;">
                삭제하시겠습니까?</b>
                 , {
                refs: 'modalis',
                title: `공지 삭제 재확인`,
                titleTag: 'h4',
                noCloseOnBackdrop: true,
                okTitle: '삭제',
                okVariant: 'danger',
                cancelTitle: '취소',
                centered: true,
                }).then(async ok => {
                if(ok) {
                    let result = await store.dispatch('board/deleteBoard', {id: this.selected._id})
                    if(result.data.deletedCount) {
                        let idx = this.boardList.findIndex(e => e._id === this.selected._id)
                        this.boardList.splice(idx, 1)
                        this.$refs[`selectableTableModal`].hide()
                    }
                    console.log('Delect :: ', result)
                }
            })
        }
    },
}
</script>
<style scoped>

</style>